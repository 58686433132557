import React, { useState, useEffect } from "react";
import axios from "axios";

const Gateway = () => {
  const [RazorPayout, setRazorpayout] = useState(true);
  const [RazorDeposit, setRazorDeposit] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState("");
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [decentroPayout, setdecentropayout] = useState(true);
  const [decentroDeposit, setdecentroDeposit] = useState(true);
  const [decentroAuto, setdecentroAuto] = useState(false);
  const [settingId, setSettingId] = useState("");

  const [isCashFreeActive, setIsCashFreeActive] = useState(false);
  const [isPhonePeActive, setIsPhonePeActive] = useState(false);
  const [isRazorPayActive, setIsRazorPayActive] = useState(false);
  const [isDecentroActive, setIsDecentroActive] = useState(false);
  const [isManualPaymentActive, setIsManualPaymentActive] = useState(false);
  const [isManualUPIQR, setIsManualUPIQR] = useState("");
  const [isManualUPIid, setIsManualUPIid] = useState("");
  const [isUpiGatewayActive, setIsUpiGatewayActive] = useState(false);
  const [isMypayActive, setIsMypayActive] = useState(false);
  const [isManualPayoutActive, setIsManualPayoutActive] = useState(false);
  const [isManualBankPayoutActive, setIsManualBankPayoutActive] =
    useState(false);
  const [isCashFreePayoutActive, setIsCashFreePayoutActive] = useState(false);
  const [isRazorPayPayoutActive, setIsRazorPayPayoutActive] = useState(false);
  const [isDecentroPayoutActive, setIsDecentroPayoutActive] = useState(false);
  const [maxAutopayAmt, setMaxAutopayAmt] = useState(0);
  const [isMypayPayoutActive, setIsMypayPayoutActive] = useState(false);
  const [isMypayPayoutBankActive, setIsMypayPayoutBankActive] = useState(false);
  const [isDecentroPayoutAuto, setIsDecentroPayoutAuto] = useState(false);
  const [isMypayPayoutAuto, setIsMypayPayoutAuto] = useState(false);
  const [isRazorPayPayoutAuto, setIsRazorPayPayoutAuto] = useState(false);

  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  useEffect(() => {
    axios.get(baseUrl + "gatewaysettings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId(res.data._id ? res.data._id : "");
      setRazorpayout(res.data.RazorPayout);
      setRazorDeposit(res.data.RazorDeposit);
      setRazorpayAuto(res.data.RazorpayAuto);
      setdecentropayout(res.data.decentroPayout);
      setdecentroDeposit(res.data.decentroDeposit);
      setdecentroAuto(res.data.decentroAuto);
      setRazorpayKey(res.data.RazorPayKey);
      setRazorpaysecretKey(res.data.RazorPaySecretKey);
      setAccountName(res.data.AccountName);

      setIsCashFreeActive(res.data.isCashFreeActive);
      setIsPhonePeActive(res.data.isPhonePeActive);
      setIsRazorPayActive(res.data.isRazorPayActive);
      setIsDecentroActive(res.data.isDecentroActive);
      setIsManualPaymentActive(res.data.isManualPaymentActive);
      setIsManualUPIQR(res.data.isManualUPIQR);
      setIsManualUPIid(res.data.isManualUPIid);
      setIsUpiGatewayActive(res.data.isUpiGatewayActive);
      setIsMypayActive(res.data.isMypayActive);
      setIsManualPayoutActive(res.data.isManualPayoutActive);
      setIsManualBankPayoutActive(res.data.isManualBankPayoutActive);
      setIsCashFreePayoutActive(res.data.isCashFreePayoutActive);
      setIsRazorPayPayoutActive(res.data.isRazorPayPayoutActive);
      setIsDecentroPayoutActive(res.data.isDecentroPayoutActive);
      setMaxAutopayAmt(res.data.maxAutopayAmt);
      setIsMypayPayoutActive(res.data.isMypayPayoutActive);
      setIsMypayPayoutBankActive(res.data.isMypayPayoutBankActive);
      setIsDecentroPayoutAuto(res.data.isDecentroPayoutAuto);
      setIsRazorPayPayoutAuto(res.data.isRazorPayPayoutAuto);
      setIsMypayPayoutAuto(res.data.isMypayPayoutAuto);
    });
  }, [baseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await axios.post(baseUrl + `gatewaysettings`, {
      settingId,
      RazorPayout,
      RazorDeposit,
      RazorpayAuto,
      decentroPayout,
      decentroDeposit,
      decentroAuto,
      RazorPayKey,
      RazorPaySecretKey,
      AccountName,
      isCashFreeActive,
      isPhonePeActive,
      isRazorPayActive,
      isDecentroActive,
      isManualPaymentActive,
      isManualUPIQR,
      isManualUPIid,
      isUpiGatewayActive,
      isMypayActive,
      isManualPayoutActive,
      isManualBankPayoutActive,
      isCashFreePayoutActive,
      isRazorPayPayoutActive,
      isDecentroPayoutActive,
      maxAutopayAmt,
      isMypayPayoutActive,
      isMypayPayoutBankActive,
      isDecentroPayoutAuto,
      isRazorPayPayoutAuto,
      isMypayPayoutAuto,
    });
    console.log(response.data.status);
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3">
        Payment Gateway Settings
      </h4>

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit}
        method="patch"
        encType="multipart/form-data"
      >
        <div className="form-row">
          <div className="form-group col-md-4"style={{display:"none"}}>
            <label htmlFor="buttonrazpay" className="col-2 my-1">
              Razorpay Payout
            </label>
            <select
              className="form-control"
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazdep" className="col-2 my-1">
              Razorpay Deposit
            </label>
            <select
              className="form-control"
              value={RazorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazauto" className="col-2 my-1">
              RazorPay Auto
            </label>
            <select
              className="form-control"
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>


        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              Withdraw Bank
            </label>
            <select
              className="form-control"
              value={decentroPayout}
              onChange={(e) => setdecentropayout(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

          </div>
        </div>

        <div className="form-row" style={{display:"none"}}>
          <div className="form-group col-md-4">
            <label htmlFor="isCashFreeActive" className="col-2 my-1">
              CashFree Active
            </label>
            <select
              className="form-control"
              value={isCashFreeActive}
              onChange={(e) => setIsCashFreeActive(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isPhonePeActive" className="col-2 my-1">
              PhonePe Active
            </label>
            <select
              className="form-control"
              value={isPhonePeActive}
              onChange={(e) => setIsPhonePeActive(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isRazorPayActive" className="col-2 my-1">
              RazorPay Active
            </label>
            <select
              className="form-control"
              value={isRazorPayActive}
              onChange={(e) => setIsRazorPayActive(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isDecentroActive" className="col-2 my-1">
              Decentro Active
            </label>
            <select
              className="form-control"
              value={isDecentroActive}
              onChange={(e) => setIsDecentroActive(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

          </div>
        </div>

        <div className="form-row" style={{display:"none"}}>
          <div className="form-group col-md-4">
            <label htmlFor="isManualUPIQR">Manual UPI QR</label>
            <input
              className="form-control"
              type="text"
              value={isManualUPIQR}
              onChange={(e) => setIsManualUPIQR(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="isManualUPIid">Manual UPI ID</label>
            <input
              className="form-control"
              type="text"
              value={isManualUPIid}
              onChange={(e) => setIsManualUPIid(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="isUpiGatewayActive" className="col-2 my-1">
              UPI Gateway Active
            </label>
            <select
              className="form-control"
              value={isUpiGatewayActive}
              onChange={(e) => setIsUpiGatewayActive(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row" style={{display:"none"}}>
          <div className="form-group col-md-4">
            <label htmlFor="isMypayActive" className="col-2 my-1">
              MyPay Active
            </label>
            <select
              className="form-control"
              value={isMypayActive}
              onChange={(e) => setIsMypayActive(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isManualPayoutActive" className="col-2 my-1">
              Manual Payout Active
            </label>
            <select
              className="form-control"
              value={isManualPayoutActive}
              onChange={(e) =>
                setIsManualPayoutActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isManualBankPayoutActive" className="col-2 my-1">
              Manual Bank Payout Active
            </label>
            <select
              className="form-control"
              value={isManualBankPayoutActive}
              onChange={(e) =>
                setIsManualBankPayoutActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isCashFreePayoutActive" className="col-2 my-1">
              CashFree Payout Active
            </label>
            <select
              className="form-control"
              value={isCashFreePayoutActive}
              onChange={(e) =>
                setIsCashFreePayoutActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isRazorPayPayoutActive" className="col-2 my-1">
              RazorPay Payout Active
            </label>
            <select
              className="form-control"
              value={isRazorPayPayoutActive}
              onChange={(e) =>
                setIsRazorPayPayoutActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isDecentroPayoutActive" className="col-2 my-1">
              Decentro Payout Active
            </label>
            <select
              className="form-control"
              value={isDecentroPayoutActive}
              onChange={(e) =>
                setIsDecentroPayoutActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4" style={{display:"none"}}>
            <label htmlFor="maxAutopayAmt">Max AutoPay Amount</label>
            <input
              className="form-control"
              type="number"
              value={maxAutopayAmt}
              onChange={(e) => setMaxAutopayAmt(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row"style={{display:"none"}}>
          <div className="form-group col-md-4"style={{display:"none"}}>
            <label htmlFor="isMypayPayoutActive" className="col-2 my-1">
              MyPay Payout Active
            </label>
            <select
              className="form-control"
              value={isMypayPayoutActive}
              onChange={(e) =>
                setIsMypayPayoutActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isMypayPayoutBankActive" className="col-2 my-1">
              MyPay Payout Bank Active
            </label>
            <select
              className="form-control"
              value={isMypayPayoutActive}
              onChange={(e) =>
                setIsMypayPayoutBankActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isDecentroPayoutAuto" className="col-2 my-1">
              Mypay Payout Auto
            </label>
            <select
              className="form-control"
              value={isMypayPayoutAuto}
              onChange={(e) => setIsMypayPayoutAuto(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isDecentroPayoutAuto" className="col-2 my-1">
              Decentro Payout Auto
            </label>
            <select
              className="form-control"
              value={isDecentroPayoutAuto}
              onChange={(e) =>
                setIsDecentroPayoutAuto(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="isRazorPayPayoutAuto" className="col-2 my-1">
              RazorPay Payout Auto
            </label>
            <select
              className="form-control"
              value={isRazorPayPayoutAuto}
              onChange={(e) =>
                setIsRazorPayPayoutAuto(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-danger">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;
